exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-post-index-js": () => import("./../../../src/templates/author-post/index.js" /* webpackChunkName: "component---src-templates-author-post-index-js" */),
  "component---src-templates-categories-index-js": () => import("./../../../src/templates/categories/index.js" /* webpackChunkName: "component---src-templates-categories-index-js" */),
  "component---src-templates-categories-post-index-js": () => import("./../../../src/templates/categories-post/index.js" /* webpackChunkName: "component---src-templates-categories-post-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/single-post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */)
}

